import React, { useState, useEffect } from "react";
import { useMedia } from "use-media";
import Layout from "../../../components/chat-channel/Layout";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { VideoElement2 } from "../../../components/video";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import "react-accessible-accordion/dist/fancy-example.css";
import {
  cta_1,
  cta_1_mob,
  cta_2,
  cta_2_mob,
  ExistingBlogCta,
} from "./4-reasons-to-use-ms-teams-as-a-digital-helpdesk";
import { redirectToWithUtm } from "../../../components/Header";

const topImage = require("../../../assets/images/market_ui/goto_testimonial_cta.png");
const image1 = require("../../../assets/images/market_ui/slack/img_1.png");
const image2 = require("../../../assets/images/market_ui/slack/img_2.png");
const image3 = require("../../../assets/images/market_ui/slack/img_3.png");
const image4 = require("../../../assets/images/market_ui/slack/img_4.png");
const VidImg = require("../../../assets/img/integration/bamboohr/video_img_bamboo.png");

const section1 = require("../../../assets/img/blogs/slack_no_blog_1.png");
const section2 = require("../../../assets/img/blogs/slack_no_blog_2.png");
const section3 = require("../../../assets/img/blogs/slack_no_blog_3.png");
const section4 = require("../../../assets/img/blogs/slack_no_blog_4.png");
const section5 = require("../../../assets/img/blogs/slack_no_blog_5.png");
const section6 = require("../../../assets/img/blogs/slack_no_blog_6.png");
const section7 = require("../../../assets/img/blogs/slack_no_blog_7.png");
const section8 = require("../../../assets/img/blogs/slack_no_blog_8.png");
const section9 = require("../../../assets/img/blogs/slack_no_blog_9.png");
const section10 = require("../../../assets/img/blogs/slack_no_blog_10.png");
const section11 = require("../../../assets/img/blogs/slack_no_blog_11.png");
const section12 = require("../../../assets/img/blogs/slack_no_blog_12.png");
const section13 = require("../../../assets/img/blogs/slack_no_blog_13.png");
const section14 = require("../../../assets/img/blogs/slack_no_blog_14.png");
const section15 = require("../../../assets/img/blogs/slack_no_blog_15.png");
const section16 = require("../../../assets/img/blogs/slack_no_blog_16.png");
const section17 = require("../../../assets/img/blogs/slack_no_blog_17.png");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id":
      "https://workativ.com/conversational-ai-platform/slack-chatbot-guide",
  },
  headline: "Slack Chatbot Guide for your Business",
  image: "",
  author: {
    "@type": "Organization",
    name: "Team Workativ",
  },
  publisher: {
    "@type": "Organization",
    name: "Workativ",
    logo: {
      "@type": "ImageObject",
      url:
        "https://workativ.com/static/workativ-logo-5eea21f2dcd20bbbbf52debb41c0f26e.png",
    },
  },
  datePublished: "2021-08-05",
  dateModified: "2021-08-05",
};

export default function FeatureHomePage() {
  return (
    <>
      <TitleAndMetaTags
        title="Build a Slackbot for Workplace Support with No-Code Platform"
        description="FREE no-code platform enables businesses to design, automate, and implement Slackbot instantly."
        keywords={[
          "Slack Chatbot, Automation, AI Chatbot",
          "workativ free trial",
          "Slack chatbot free trial",
          "Slack Chatbot",
        ]}
        ogImage={section1}
        schema={ldSchema}
      />
      <section className="market_main_wrapper">
        <Container>
          <Layout backgroundColor={"_bg_market"} logoFor="ASSISTANT">
            <div className="grid_container_chatbot">
              <div className="container">
                <div className="row">
                  <div className="grid_container_chatbot_wrapper">
                    <div className="grid__wrapper">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 trial_header_left">
                        <div className="main-slider-left-market">
                          <h1 className="font-page-header  ">
                            Build a Slackbot for Workplace Support in Minutes
                            without coding!
                          </h1>
                        </div>
                      </div>
                      <div>
                        <p class="font-section-normal-text line-height-2">
                          Communication has changed a great deal in the last few
                          years. Where communication was once largely between
                          just people physically in the same room or same
                          building, today it's now largely between people who
                          are a great distance apart. This shift in
                          communication has been brought about by advances in
                          technology. One of the biggest changes has been the
                          advent of instant messaging and{" "}
                          <a href="https://workativ.com/conversational-ai-platform">
                            automated bots.
                          </a>
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          How about a one-stop-shop for communication needs,
                          including message scheduling, notifications, and
                          feedback?
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          We hear you,
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          In this blog, we look at why every organization needs
                          slackbots for workplace support.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Slackbot is a messaging platform that helps
                          organizations manage communication and keep everyone
                          on the same page. It's like a virtual assistant that
                          responds to messages automatically, helping to keep
                          everyone organized and on track. Slackbot is perfect
                          for any organization because it helps people
                          communicate more effectively, eliminates the need for
                          extra emailing, and keeps conversations moving
                          forward. Slackbot is a big reason why Slack is so
                          successful as a platform. This handy little feature
                          acts as an assistant to every user. It is there to
                          help you execute, automate, and complete tasks create
                          discussion channels, and more through a simple
                          message. It learns from your habits and can even
                          complete some basic tasks on its own, without you
                          needing to even log in!
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          It’s designed to make work-life easier since it can be
                          integrated with other systems, such as email and
                          calendars, to help users stay organized. It can be
                          used to respond to questions, provide suggestions, and
                          handle tasks. It can also monitor conversations and
                          alert employees when there is an important message.
                          Slackbot has been adopted by many organizations for
                          its efficiency and convenience.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Slackbot has many benefits for any organization, but
                          it especially shines in organizations with a high
                          volume of communication and workflows.{" "}
                          <a href="https://workativ.com/conversational-ai-platform/how-slack-bot-improve-hybrid-workplace">
                            Slackbots can improve communication in a hybrid
                            workplace
                          </a>{" "}
                          with their comprehensive features,{" "}
                          <a href="https://workativ.com/conversational-ai-platform/slack-bot-for-hr-and-it-support">
                            especially in the fields of HR and IT
                          </a>{" "}
                          Support. By taking advantage of Slackbot, you can
                          boost team productivity and communication.{" "}
                        </p>
                      </div>

                      <div className="market_wrapper_page">
                        <h2 className="font-section-sub-header-small-bold ">
                          Let us look at the Top 8 benefits of Slackbot for your
                          workplace support:
                        </h2>
                        <h6 className="font-section-sub-header-small ">
                          Communicate effectively
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          Businesses that use Slack often have strong
                          communication, and using a chatbot can enhance that.
                          <a href="https://workativ.com/conversational-ai-platform/slack-chatbot">
                            Slackbots
                          </a>{" "}
                          can organize meetings and give information
                          instantaneously. Now your employees can use their time
                          on more complex tasks rather than replying to
                          inquiries or scheduling meetings
                        </p>
                        <h6 className="font-section-sub-header-small">
                          Organize tasks easily
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          On average we spend at least 60 - 80 mins on switching
                          apps, not only is this a huge time killer it can also
                          affect your machine making it slower eventually. A
                          slackbot can assist your organization to integrate all
                          business systems and serve as a binder. Instead of
                          switching from one software to another, employees will
                          be able to pose a query or place a request to your
                          Slackbot to do whatever tasks they need.
                        </p>
                        <h6 className="font-section-sub-header-small">
                          Ensure all of your departments are in harmony
                        </h6>
                        <p class="font-section-normal-text  line-height-2">
                          Successful company models divide their activities into
                          several divisions. The majority of the time, each
                          department is dealt with separately, which can lead to
                          synchronization problems and poor experiences.
                          Slackbots can synchronize all departments by sending
                          out notifications, verifying that they've been sent,
                          and addressing any inquiries about the change
                        </p>

                        <h5 className="font-section-sub-header-small">
                          PA with no cost
                        </h5>

                        <p class="font-section-normal-text  line-height-2">
                          You know hiring an assistant isn’t an easy job. It is
                          time-consuming and expensive. What if you could have a
                          personal assistant not only for yourself but your
                          entire team? That too, for free.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          A Slackbot is the best PA one can have. It will
                          provide you and all of your workers with a dedicated
                          assistant to help you manage your tasks without having
                          to spend a penny.
                        </p>

                        <h5 className="font-section-sub-header-small ">
                          Big data analysis
                        </h5>

                        <p class="font-section-normal-text line-height-2">
                          Most organizations rely heavily on big data to
                          succeed. Big data specialists don't come easily. You
                          need to devote hours of effort to evaluating these
                          data which can be expensive and cumbersome. Slackbots
                          can come to your rescue again. It can assist you by
                          classifying and analyzing large amounts of data in a
                          short period. The greatest feature is that they can
                          report these results to stakeholders, relevant members
                          of the team, or concerned parties as requested.
                        </p>
                        <h6 className="font-section-sub-header-small ">
                          Increased scalability{" "}
                        </h6>

                        <p class="font-section-normal-text line-height-2">
                          Many software solutions have one major flaw: they're
                          not designed to grow. Bots on the other hand are built
                          to engage with thousands of people at once, making
                          them an excellent tool that will grow with your
                          organization as it grows.
                        </p>

                        <h5 className="font-section-sub-header-small">
                          Reduce the amount of time it takes to complete a task
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          <a href="https://workativ.com/">Chatbots</a> can
                          drastically decrease turnaround and production time
                          without sacrificing quality since they increase
                          communications, assist your staff manage their time,
                          and eliminate the need for unimportant activities.
                        </p>
                        <h6 className="font-section-sub-header-small">
                          Facilitate smoother business operations
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          Companies that supply services and goods to other
                          companies can employ Slackbots to deliver a more
                          streamlined and{" "}
                          <a href="https://workativ.com/conversational-ai-platform/blog/conversational-ai-focus-on-user-experience">
                            seamless experience for customers.
                          </a>
                          Instead of overburdening your sales force, you can
                          have your bot handle queries and train it to send in a
                          sales professional after your prospective customers
                          have shown genuine interest in your goods or services.
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          All this sounds amazing but I am sure it will be
                          complicated to build a slackbot. We would need
                          extensive coding skills, a lot of time and it will be
                          really expensive. I don’t think we are ready for it
                          now.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          This is what our first customer told us before our
                          demo.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          But not long after that, they have referred Workativ
                          to many organizations and our partnership has been
                          going stronger than ever.
                        </p>
                      </div>

                      <NocodeWrapper />

                      <div>
                        <h6 className="font-section-sub-header-small-bold">
                          Build a Slack bot for your workplace in minutes with
                          no coding required!
                        </h6>
                        <h6 className="font-section-sub-header-small">
                          Introducing Workativ
                        </h6>

                        <p class="font-section-normal-text mt-4 line-height-2">
                          Workativ is a FREE no-code SaaS platform that enables
                          businesses to design, automate, and implement
                          conversational AI chatbots in under 60 minutes. It is
                          a cognitive workplace automation platform designed for
                          IT teams to streamline services and provide
                          outstanding workplace IT support for employees.
                        </p>
                        <h6 className="font-section-sub-header-small-bold">
                          What makes it different from the rest?
                        </h6>

                        <p class="font-section-normal-text line-height-2">
                          Workativ has a sophisticated app workflow automation
                          engine and an out-of-the-box automation marketplace
                          with prebuilt bots, as well as 600+ pre-built app
                          workflows for the chatbot, in addition to the chatbot.
                          <a href="https://workativ.com/conversational-ai-platform">
                            Workativ Assistant
                          </a>{" "}
                          can entirely automate a request, such as new employee
                          onboarding, distribution list creation and management,
                          account unlocks, password resets, ticket creation, and
                          much more.
                        </p>
                        <p class="font-section-normal-text mb-0 pb-0">
                          The result?
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Thanks to a completely automated solution with an
                          automation marketplace for processes, the time spent
                          answering problems and requests is substantially
                          reduced from hours to a few seconds allowing workers,
                          HR, and{" "}
                          <a href="https://workativ.com/conversational-ai-platform/it-helpdesk-chatbot">
                            IT Help Desk support
                          </a>{" "}
                          staff to be more productive.
                        </p>
                        <h6 className="font-section-sub-header-small">
                          Employee satisfaction is just a click away
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          Employees want real-time support regardless of where
                          they are or whatever channel they use — a demand that
                          every organization must meet. Employees expect
                          organizations to deliver a smooth{" "}
                          <a href="https://workativ.com/conversational-ai-platform/self-service-automation">
                            employee service experience.
                          </a>{" "}
                          This basically involves providing the staff with
                          24-hour support on their smartphones through their
                          preferred chat channels. With Workativ Assistant, you
                          have the freedom to provide precisely the sort of help
                          that your employees want.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          At Workative, we've integrated our process automation
                          and conversation builders into a single platform that
                          makes it simple to trial out, design, and deploy an
                          intelligent virtual assistant at a low cost. Workativ
                          Assistant enables you to automate time-consuming tasks
                          and provide self-service assistance to workers through
                          virtual assistants, enabling you to deliver
                          round-the-clock IT support.
                        </p>
                        <h6 className="font-section-sub-header-small-bold">
                          Benefits of Workativ
                        </h6>

                        <p class="font-section-normal-text line-height-2">
                          Workativ Assistant is a cognitive workplace automation
                          suite that aims to change the way businesses deliver
                          IT help to their employees. Workativ Assistant assists
                          businesses in creating a purpose-built Virtual
                          Assistant with RPA capabilities to tackle recurring
                          workplace concerns and service requests.
                        </p>
                        <ul
                          class="font-section-normal-text"
                          style={{ width: "100%", float: "left" }}
                        >
                          <li>
                            Workativ Assistant's key features include the
                            ability for businesses to quickly develop, deploy,
                            and train Virtual Assistants using a simple,
                            easy-to-use visual interface.
                          </li>
                          <li>
                            It includes a comprehensive IT Process editor that
                            allows you to develop, create, and execute complex
                            IT processes.
                          </li>
                          <li>
                            <a href="https://workativ.com/conversational-ai-platform/what-is-workativ-assistant">
                              Virtual assistants
                            </a>{" "}
                            can be easily implemented on well-known
                            conversational channels like Slack, Skype, or even
                            self-service portals.
                          </li>
                          <li>
                            Workativ Assistant provides great flexibility to IT
                            teams by automating time-consuming processes and
                            allowing end-users to access suitable workplace help
                            from anywhere and at any time.
                          </li>
                        </ul>
                        <p class="font-section-normal-text line-height-2">
                          Make your IT Helpdesk more proactive to increase the
                          efficiency of your IT employees. Workativ Assistant
                          has a user-friendly interface, a revolutionary new
                          approach to SLAs, and customized workflows to automate
                          time-consuming tasks. It works with a variety of
                          communication platforms, including Slack, Teams,
                          Skype, including your current portal.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Workativ Assistant enables IT, teams, to serve workers
                          more quickly than ever before. We've progressed beyond
                          traditional workplace help systems with our solution,
                          including Helpdesk-specific features like:
                        </p>
                        <h6 className="font-section-sub-header-small">
                          Simple Easy to Use interface (EUS)
                        </h6>

                        <p class="font-section-normal-text line-height-2">
                          A user interface that is simple and easy to use in
                          order to develop sophisticated IT processes that
                          include different aspects such as forms, approval,
                          verification, ticket production, and so on. It is
                          engineered for IT teams to focus on employee/user
                          satisfaction while eliminating design challenges.
                        </p>
                        <h6 className="font-section-sub-header-small">
                          Automation Catalogue
                        </h6>

                        <p class="font-section-normal-text line-height-2">
                          An extensive repository of pre-defined automation
                          workflows that allow low-coders to effortlessly manage
                          and execute basic IT processes. Designed for IT teams
                          to begin their workplace automation straight away
                          rather than designing workflows/processes from the
                          ground up.
                        </p>
                        <h6 className="font-section-sub-header-small">
                          Conversational forms
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          Gather useful information from the end-user in a more
                          engaging and interactive manner. To provide seamless
                          workplace assistance, create interactive forms that
                          encourage users to contribute the information you
                          need.
                        </p>

                        <h6 className="font-section-sub-header-small">
                          Flexible Digital Experience
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          Workativ Assistant can be integrated with popular
                          digital channels to provide users with immediate
                          workplace support from any location at any time. Users
                          can instantly use their chosen app, such as Slack or
                          Skype, to report workplace concerns, follow up, and
                          get them fixed without the need for external
                          assistance.
                        </p>
                        <h5 className="font-section-sub-header-small">
                          Transform your IT support
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Convert your present IT support to a digital model
                          with RPA-induced Virtual Assistants in days.
                          Proactively addressing workplace concerns in a more
                          intelligent manner can enhance the workplace support
                          experience for users. Instead of just focusing on
                          tickets, concentrate more on resolving issues.
                        </p>
                        <h6 className="font-section-sub-header-small">
                          Automation Builder
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          Customize processes using an easy-to-use workflow
                          generator, integrate with all critical business
                          systems, and automated from start to end. Save time by
                          regularly analyzing and modifying processes to improve
                          the support experience.
                        </p>
                        <h6 className="font-section-sub-header-small">
                          Dialogue Builder
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          Create logic-driven conversations for users to employ
                          in order to raise and address workplace concerns in a
                          more conversational style. With our sophisticated
                          intent detection tool, you can convert any complicated
                          interactions into human-like dialogues.
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          Employees and your IT Helpdesk staff will benefit from
                          a contemporary, flexible workplace IT support
                          experience. You'll be able to delight your staff while
                          also increasing overall productivity and boosting
                          profitability. Workativ Assistant gives your IT
                          Helpdesk staff the power of cognition, simplicity, and
                          automation.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          <a href="https://workativ.com/conversational-ai-platform/slack-chatbot">
                            Try Workativ Assistant now for free.
                          </a>
                        </p>
                        <h5 className="font-section-sub-header-small-bold">
                          Other amazing features of Workativ include -
                        </h5>
                        <ul
                          class="font-section-normal-text"
                          style={{ width: "100%", float: "left" }}
                        >
                          <li>Chatbot Builder </li>
                          <li>App Workflow Builder </li>
                          <li> FAQ Builder</li>
                          <li>Create Multiple Bots </li>
                          <li>Multi-Step App Workflows</li>
                          <li>
                            Bot Marketplace (Pre-built Bots & App workflows)
                          </li>
                          <li>
                            Bot training, testing, logs, performance dashboard{" "}
                          </li>
                          <li>Approval Management </li>
                          <li>On-prem App Connector </li>
                          <li>
                            Chat Channel Integration (e.g., Teams, Slack, etc)
                          </li>
                          <li>Chat Widget</li>
                          <li>Live Agent Handover (e.g., Teams)</li>
                          <li>Bot fully customizable using No-Code. </li>
                          <li>Digital Onboarding </li>
                          <li>70+ App Integrations</li>
                          <li>600+ App Workflows</li>
                        </ul>
                        <h5 className="font-section-sub-header-small-bold">
                          Now let us look at a real-time example of how Workativ
                          actually works:
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Here we have taken a pre-built Support Chatbot as an
                          example. Let us see how to download pre-built app
                          workflows and go live instantly using just one-click
                          integration.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Here we have considered the use case of “unlock
                          account” queries.
                        </p>

                        <h5 className="font-section-sub-header-small">
                          Step 1. Creating a bot workspace
                        </h5>
                        <p class="font-section-normal-text mt-0 pt-0 mb-3 line-height-2">
                          When you sign up for Workativ Assistant, you’ll be
                          required to name your workspace. You can provide the
                          name of your organization as a workspace name.
                        </p>
                        <img
                          loading="lazy"
                          src={section1}
                          alt="Creating a bot workspace"
                        />
                        <h6 className="font-section-sub-header-small mb-0 pb-0 mt-5">
                          Step 2. Download your bot{" "}
                        </h6>
                        <p class="font-section-normal-text  mt-0 pt-0 mb-3 line-height-2">
                          Download a prebuilt Support bot from the bot
                          Marketplace
                        </p>
                        <img
                          loading="lazy"
                          src={section2}
                          alt="Download your bot"
                        />
                        <p class="font-section-normal-text line-height-2 mb-0 pb-0  mt-5">
                          There are many workflows available as shown in the
                          image below in dialogs home page. Configure you
                          Support Bot with you favorite app workflows (unlock
                          account, password resets, user provisioning, access
                          management, asset requests, apply leave, onboarding,
                          and much more)
                        </p>
                        <img
                          loading="lazy"
                          src={section3}
                          alt="prebuilt Support bot"
                        />
                        <p class="font-section-normal-text line-height-2 mb-0 pb-0 mt-5">
                          Once you are done connecting the respective workflows
                          you can click try me to test the conversation.
                        </p>
                        <img loading="lazy" src={section4} alt="workflows" />
                        <h6 className="font-section-sub-header-small mb-0 pb-0 mt-5 pt-2">
                          Step 3: Deploy the Support bot in your favorite chat
                          channel
                        </h6>
                        <img
                          loading="lazy"
                          src={section5}
                          alt="Deploy the Support bot"
                        />
                        <img
                          loading="lazy"
                          src={section6}
                          class="mt-4 pt-4"
                          alt=" chat
                          channel"
                        />

                        <p class="font-section-normal-text line-height-2 mt-4">
                          Congratulations! Your Bot is ready to serve you.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          In conclusion, Slackbots can be created without any
                          coding in minutes with Workativ. They provide support
                          for workplace issues and can be customized to fit the
                          needs of your business.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          <a href="https://workativ.com/conversational-ai-platform/slack-chatbot">
                            Try Workativ Assistant now for free.
                          </a>
                        </p>
                      </div>
                      <ExistingBlogCta
                        ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
                        ButtonText="Book a Demo"
                        isColor="black"
                        backgroundImage={cta_1}
                        mobileBackgroundImage={cta_1_mob}
                      />
                      <div className="most_popular_links">
                        <div className="most_popular_header font-section-sub-header-bold">
                          Related Articles
                        </div>
                        <div className="most_popular_ul">
                          <ul
                            className="section__ul"
                            style={{ width: "100%", float: "left" }}
                          >
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/how-slack-bot-improve-hybrid-workplace">
                                How Slack Bot can improve communication in a
                                hybrid workplace?
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/slack-bot-for-hr-and-it-support">
                                {" "}
                                Unleashing the Power of Slackbots in the HR and
                                IT Support
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/slack-chatbot-guide">
                                Slack Chatbot Guide for IT Helpdesk Automation
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="button_last">
                        <button>
                          <a href="https://blog.workativ.com/category/conversational-ai">
                            Conversational AI
                          </a>
                        </button>
                      </div>
                    </div>
                    <div className="sticky_right_img">
                      <a href="/conversational-ai-platform/case-studies/goto-implements-chatbot-for-it-helpdesk-automation">
                        {" "}
                        <img src={topImage} alt="goto testimonial cta" />{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <GoToPopup />
            <CookiesPoup /> */}
            <RequestForm isFooterForm={true} />
          </Layout>
        </Container>
      </section>
    </>
  );
}

const NocodeWrapper = () => {
  return (
    <div className="nocode_wrapper mt-0 ">
      <h4>No Code - Free Slack Chatbot </h4>
      <button>
        <a
          href="https://assistant.workativ.com/authentication/u/direct-signup"
          alt="Conversational AI chatbot"
        >
          Get Started
        </a>
      </button>
    </div>
  );
};
